import timg1 from '../images/team/1.jpg'
import timg2 from '../images/team/2.jpg'
import timg3 from '../images/team/3.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        name:'Harry Abraham',
        title:'Founder',
    },
    {
        Id: '2',
        tImg:timg2,
        name:'Robert Johnson',
        title:'Admin',
    },
    {
        Id: '3',
        tImg:timg3,
        name:'Jenelia Orkid',
        title:'Merketer',
    }
]

export default Teams;
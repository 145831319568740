import React, {Fragment, useEffect} from 'react';
import About from '../../components/about/about';
// import BlogSection from '../../components/BlogSection/BlogSection';
import CtaSection from '../../components/ctaSection/ctaSection';
import Footer from '../../components/footer/Footer';
import Footer2 from '../../components/footer2/Footer2';
// import FunFact from '../../components/FunFact/FunFact';
import Hero2 from '../../components/hero2/Hero2';
import Navbar2 from '../../components/Navbar2/Navbar2'
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
// import ServiceSection from '../../components/ServiceSection/ServiceSection';
// import TeamSection from '../../components/TeamSection/TeamSection';
// import Testimonial from '../../components/Testimonial/Testimonial';
//import PartnerSection from '../../components/PartnerSection/PartnerSection';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import abimg from '../../images/about/Home_3.jpg'
import Logo from '../../images/logo-3.png'
//import ServiceSectionS3 from '../../components/ServiceSectionS3/ServiceSectionS3';




const HomePage2 =() => {
    return(
        <Fragment>
            <Navbar2 hclass={'wpo-header-style-1'} Logo={Logo}/>
            <Hero2/>
            <About abimg={abimg}/>
            {/* <ServiceSection/> */}
            {/*<ServiceSectionS3/>*/}
            {/* <FunFact/> */}
            <ProjectSection/>
            {/* <Testimonial/> */}
            {/* <BlogSection/> */}
            {/* <PartnerSection/>*/}
            {/* <ContactInfo/> */}
            <CtaSection/>
            {/* <Footer/> */}
            <Footer2/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage2;
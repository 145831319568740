import React from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/slide-7.jpg'
import hero2 from '../../images/slider/slide-8.jpg'
import hero3 from '../../images/slider/slide-9.jpg'

const settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    fade: true,
    pauseOnHover:false,
};

const Hero2 = () => {
    return (
        <section className="wpo-hero-slider">
            <div className="hero-container">
                <div className="hero-wrapper">
                    <Slider {...settings}>
                        <div className="hero-slide">
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                                <div className="container-fluid">
                                    <div className="slide-content">
                                        <div className="slide-title-sub">
                                            <h5>“Let your design take flight with Pegasus GDP!”</h5>
                                        </div>
                                        <div className="slide-title">
                                            <h2>Bring your design to life</h2>
                                        </div>
                                        <div className="slide-text">
                                            <p>Our Engineering and Design Team is ready to take your idea from the ground up to a fully manufacturable solution.</p>
                                        </div>
                                        <div className="btns">
                                            <Link to="/../services/1" className="theme-btn">Explore More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="hero-slide">
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero2})` }}>
                                <div className="container-fluid">
                                    <div className="slide-content">
                                        <div className="slide-title-sub">
                                            <h5>A Trusted Global Manufacturing Network</h5>
                                        </div>
                                        <div className="slide-title">
                                            <h2>Manufacture with Confidence</h2>
                                        </div>
                                        <div className="slide-text">
                                            <p>Our Factory Partners and Manufacturing Liaisons are focused on quality and safety, while ensuring we meet your targets for timing and cost.</p>
                                        </div>
                                        <div className="btns">
                                            <Link to="/../services/2" className="theme-btn">Explore More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="hero-slide">
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero3})` }}>
                                <div className="container-fluid">
                                    <div className="slide-content">
                                        <div className="slide-title-sub">
                                            <h5>Delivering Solutions-Fast AND EFFICIENTLY</h5>
                                        </div>
                                        <div className="slide-title">
                                            <h2>Find & Deliver your solution</h2>
                                        </div>
                                        <div className="slide-text">
                                            <p>Our Sourcing & Logistics specialists are ready to find existing solutions and deliver them wherever needed worldwide.</p>
                                        </div>
                                        <div className="btns">
                                            <Link to="/../services/3" className="theme-btn">Explore More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Hero2;
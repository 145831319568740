import React, { useState,useEffect  } from 'react'
import { Link } from 'react-router-dom'
import HeaderTopbar from '../HeaderTopbar/HeaderTopbar'
import MobileMenu from '../MobileMenu/MobileMenu'
import { colors } from '@material-ui/core'
import { useRef } from 'react';
import Logo from '../../images/logo.png'
import Logo2 from '../../images/logo2small.png'







const Header2 = (props) => {
    const [menuActive, setMenuState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault()


    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    let logoSelected = Logo;


    // const [dimensions, setDimensions] = useState({
    //     width: window.innerWidth,
    //     height: window.innerHeight,
    //   });
      
    //   if(window.innerWidth<='991'){
    //     logoSelected=Logo2;
    //   }
    //     else{
    //         logoSelected=Logo
    //     };
      
    // const handleResize = () => {
    //     setDimensions({
    //     width: window.innerWidth,
    //     height: window.innerHeight,
    //     });
    //   }
      
    // useEffect(() => {
    //   window.addEventListener("resize", handleResize, false);
    // }, []);



    // const windowSize = useRef([window.innerWidth, window.innerHeight]);
    // console.log(windowSize.current[0]);
    // if(windowSize.current[0]<='991'){

    //     logoSelected=Logo2;
    //     console.log(logoSelected);
    //     console.log(Logo);
    // }
    // else{
    //     console.log(logoSelected);
    //     console.log(Logo);
    // }

    return (
        <header id="header" className={props.topbarNone}>
            {/* <HeaderTopbar /> */}
            <div className={`wpo-site-header ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/home"><img src={logoSelected}
                                        alt="" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li><Link onClick={ClickHandler} to="/home">Home</Link></li>
                                        <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                        <li className="menu-item-has-children">
                                            <Link to="/services">Services</Link>
                                            <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/services/1">ODM Engineering & Development</Link></li>
                                                <li><Link onClick={ClickHandler} to="/services/2">Direct Manufacturing</Link></li>
                                                <li><Link onClick={ClickHandler} to="/services/3">OEM Sourcing & Logistics</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link onClick={ClickHandler} to="/network-locations">Network</Link></li>
                                        <li><Link onClick={ClickHandler} to="/blog">Blog</Link></li> 
                                        <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-2 col-2">
                                <div className="header-right">
                                    {/* <div className="header-search-form-wrapper">
                                        <div className="cart-search-contact">
                                            <button onClick={() => setMenuState(!menuActive)} className="search-toggle-btn"><i
                                                className={`fi ti-search ${menuActive ? "ti-close" : "fi "}`}></i></button>
                                            <div className={`header-search-form ${menuActive ? "header-search-content-toggle" : ""}`}>
                                                <form onSubmit={SubmitHandler}>
                                                    <div>
                                                        <input type="text" className="form-control"
                                                            placeholder="Search here..." />
                                                        <button type="submit"><i
                                                            className="fi flaticon-search-interface-symbol"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='social-media-container'>

                                            <Link to="https://www.linkedin.com/company/pegasusgdp/" target='_blank'><i className="ti-linkedin"></i></Link>
                                            <Link to="https://www.youtube.com/@PegasusGDP/featured" target='_blank'><i className="ti-youtube"></i></Link>
                                            <Link to="https://www.instagram.com/pegasusgdp/" target='_blank'><i className="ti-instagram"></i></Link>
                                            <Link to="https://twitter.com/PegasusGDP" target='_blank'><i className="ti-twitter"></i></Link>

                                    </div>



                                    <div className="close-form">
                                        <Link onClick={ClickHandler} className="theme-btn navbarBtn" to="/contact">
                                            Contact Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header2;
import React from 'react'
import { Link } from 'react-router-dom'
import abimg2 from '../../images/about/Home_3.jpg'
import shape1 from '../../images/ab-shape-1.png'
import shape2 from '../../images/ab-shape-2.png'
import { colors } from '@material-ui/core'
import { red } from '@material-ui/core/colors'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About = (props) => {
    return(
        <section className={`wpo-about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12 imgholder">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={props.abimg} alt=""/>
                                {/* <div className="wpo-ab-shape-1"><img src={shape1} alt=""/></div>
                                <div className="wpo-ab-shape-2"><img src={shape2} alt=""/></div> */}
                                {/* <div className="wpo-about-exprience">
                                    <h3>10 Years Experience</h3>
                                </div> */}
                                <div className="wpo-ab-img-2">
                                    <img src={abimg2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 maintext">
                        <div className="wpo-about-text">
                            {/* <h4>“Let your design take flight with Pegasus GDP!”</h4> */}
                            <h2 className='maintexttop'>We're capable of diving in <span><b style={{color: '#ff4a17'}}>anywhere</b></span> in your product <b><span>lifecycle</span></b></h2>
                            <p>Pegasus Global Design Partners is a team of engineering and supply chain consultants committed to delivering technical product and component solutions worldwide. Capable of diving in anywhere in your product lifecycle, Pegasus GDP can provide design, development, sourcing, and logistics services, including:</p>
                                <ul className='leftrow respAbout'>
                                    <li className='firstitem'>Engineering Services</li>
                                    <li>Electrical Engineering</li>
                                    <li>Hardware Design</li>
                                    <li>PCB Design </li>
                                    <li>Mechanical Engineering</li>
                                    <li>3D Modeling & Simulation</li>
                                    <li>Design for Manufacturing (DFM)</li>
                                    <li>Web Design & Implementation</li>
                                    <li>Packaging Design</li>
                                    <li>System Architecture</li>
                                    <li>Wiring Design</li>
                                </ul>
                                <ul className='rightrow respAbout'>
                                    <li className='firstitem'>Manufacturing Capabilities</li>
                                    <li>Metal Forming</li>
                                    <li>Injection Molding</li>
                                    <li>Thermoforming</li>
                                    <li>PCB / Circuit Board Assembly</li>
                                    <li>Wiring & Cable Harnesses</li>
                                    <li>LED Lighting (Indoor & Commercial)</li>
                                    <li>LED Lighting (Agriculture & UV)</li>
                                    <li>Printing</li>
                                    <li>Assembly</li>
                                    <li>Sourcing</li>  
                                </ul>
                            {/* <Link onClick={ClickHandler} className="theme-btn" to="/about">More About Us</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;
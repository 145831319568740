import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/about/ab3.jpg'

const ClickHandler = (props) => {
    window.scrollTo(10, 0);
}


const About4 = (props) => {
    return (
        <section className="wpo-about-section-s4 section-padding pb-120 mt-5">
            <div className="container">
                <div className="wpo-about-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                {/* <h4>Pegasus</h4> */}
                                <h2>We are a trusted consulting group.</h2>
                                <p>Pegasus Global Design Partners is a consulting group consisting of professionals across the product lifecycle, including mechanical and electrical engineers, sourcing specialists, factory engineers, quality auditors, packaging developers, and logistics providers.  Additionally, our consultants are located throughout North America and across Asia to provide in person contact and boots on the ground coverage wherever your supply chain takes you.</p>
                                <div className="text-sub-wrap">
                                    <div className="text-sub-item">
                                        {/* <i className="fi flaticon-tax"></i> */}
                                        <h5>This network of expertise allows Pegasus GDP to tackle challenges and provide value from start to finish in any product field.</h5>
                                    </div>
                                </div>
                                <Link onClick={ClickHandler} className="theme-btn" to="/services">Our Services</Link>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-wrap">
                                <div className="wpo-about-img">
                                    <div className="inner-img">
                                        <img src={abimg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-ab"></div>
        </section>
    )
}

export default About4;
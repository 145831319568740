import pimg1 from '../images/project/img-1-1.jpg'
import pimg2 from '../images/project/img-2-2.jpg'
import pimg3 from '../images/project/img-3-3.jpg'
// import pimg4 from '../images/project/img-31.jpg'
// import pimg5 from '../images/project/img-4.jpg'
// import pimg6 from '../images/project/img-5.jpg'
// import pimg7 from '../images/project/img-6.jpg'
// import pimg8 from '../images/project/img-7.jpg'
// import pimg9 from '../images/project/img-8.jpg'
// import pimg10 from '../images/project/1.png'
// import pimg11 from '../images/project/2.png'
// import pimg12 from '../images/project/3.png'
// import pimg13 from '../images/project/4.png'
import pimg14 from '../images/project/Project-IMG-3.jpg'
import pimg15 from '../images/project/Project-IMG-4.jpg'
import pimg16 from '../images/project/Project-IMG-5.jpg'

import ps1img1 from '../images/project-single/1.jpg'
import ps1img2 from '../images/project-single/2.jpg'
import ps1img3 from '../images/project-single/3.jpg'
// import ps1img4 from '../images/project-single/4.jpg'
// import ps1img5 from '../images/project-single/1.jpg'
// import ps1img6 from '../images/project-single/2.jpg'
// import ps1img7 from '../images/project-single/3.jpg'
// import ps1img8 from '../images/project-single/5.jpg'
// import ps1img9 from '../images/project-single/6.jpg'


import psub1img1 from '../images/project-single/p1.jpg'
import psub1img2 from '../images/project-single/p2.jpg'




const Projects = [
    {
       Id:'1',
       pImg:pimg1,
       ps1img:ps1img1,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       pimg14:pimg14,
       title:'ODM Engineering & Development',  
       subTitle:'Electrical & Mechanical Engineering', 
       data:"Ready to bring your unique product ideas and concepts to life?  Pegasus GDP has the design resources to take our clients through the entire development phase, or any portion or gap within.  This includes Electrical and Mechanical Engineering, Packaging Design, Prototyping, Testing, and Preparation for Manufacturing.\n\nPegasus GDP Engineers and Designers have deep experience in bringing ideas to market, successfully launching hundreds of product families into both retail and commercial markets.  We value the opportunity to apply this expertise to your project." ,
       services: "Pegasus GDP Engineers provide design and prototyping services, including:",
       servicesArray:["Mechanical Engineering – CAD / 3D Modeling","Electrical Engineering – Circuit Design, PCBA Design, Cable Harnesses","Lighting Design – Board Layout, Distribution Analysis","Packaging Design – Artwork Development, Marketing Materials"],
       servicesArray2:[],
    },

    {
      Id:'2',
      pImg:pimg3,
      ps1img:ps1img3,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      pimg14:pimg16,
      title:'Direct Manufacturing',  
      subTitle:'Overseas & Domestic',
      data:"Have a design package ready for production? Pegasus GDP Partners are standing by ready to begin.  We cover the entire manufacturing base, from up-stream material processing, to final integration for complex products.  Pegasus GDP Manufacturing Specialists are available to review and supervise on-site at factory locations.\n\n Our Partners include Industrial Fabricators that can perform forming, molding, and processing of metals, plastics, glass, wood, and other materials.  Looking further downstream, our Integrators and Assembly Houses can take hundreds, even thousands, of components and assemble, test, and package for shipment.  Whether your manufactured good is a base material component or a full assembly in retail packaging, Pegasus GDP has reliable factory partners to support your project both overseas and domestically here in the USA.",
      services: "Pegasus GDP Factory Partners & Manufacturing Specialists provide reliable production and inspection services, including:",
      servicesArray:["Metal Forming",
      "Injection Molding",
      "Thermoforming",
      "PCBA/Circuit Card Assembly",
      "Wiring & Cable Harnesses",
      "Paper & Card Printing",
      "Production Sample Review",
      "Factory Visit & Auditing",],
      servicesArray2:["FDA-Approved Manufacturing",
      "Automation & Integration",
      "Custom Battery Manufacturing",
      "Fabric & Textiles",
      "Production Line Supervision",
      "Daily Production Reporting",
      "Quality & Compliance Reviews",
      ],
      
   
   },
    {
       Id:'3',
       pImg:pimg2,
       ps1img:ps1img2,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       pimg14:pimg15,
       title:'OEM Sourcing & Logistics', 
       subTitle:'Production Oversight',
       data: "Need an off-the-shelf part at a competitive price and don’t want to worry about transporting your goods?  Pegasus GDP has a team of Sourcing Experts who can find existing items available direct from vendors, while our Logistics Specialists can move goods fast and efficiently, all over the world. \n\nOur Sourcing and Logistics team is committed to finding quality components from reliable vendors.  We also understand that competitive pricing is key to success in any industry and will negotiate for the best prices and terms.  Having established vendor relationships and frontline product quality specialists allows us to provide competitive solutions without sacrificing quality.",
       services: "Pegasus GDP Sourcing specialists provide factory identification, assessment, and production oversight, including:",
       servicesArray:["Sourcing – Identify Factory Partners","Quality Control – Define & Maintain High Standards","Cost Negotiations – Drive Best Costing by Volume","Logistics – Door to Door Service Hassle-Free"],
       servicesArray2:[],
   
    },



]

export default Projects;
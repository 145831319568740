import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'
import shape from '../../images/ft-shape.png'
import shape2 from '../../images/ft-shape2.png'
import blognew from '../../api/blogdata'


const ClickHandler = () => {
    window.scrollTo(10, 0);
}
const SubmitHandler = (e) => {
    e.preventDefault()
}

const Footer2 = (props) => {
    return (
        <footer className="wpo-site-footer-s2">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="logo"/>
                                </div>
                                <p>We are committed to providing quality support and service to meet all our customers’ needs. From engineering to production, prototyping to delivery, we are here to add value.</p>
                                <ul>
                                    <li>
                                        <Link onClick={ClickHandler} to="https://www.linkedin.com/company/pegasusgdp/" target='_blank'>
                                            <i className="ti-linkedin"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="https://www.youtube.com/@PegasusGDP/featured" target='_blank'>
                                            <i className="ti-youtube"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="https://www.instagram.com/pegasusgdp/" target='_blank'>
                                            <i className="ti-instagram"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="https://twitter.com/PegasusGDP" target='_blank'>
                                            <i className="ti-twitter"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Index</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/home">Home</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/services">Services</Link></li>
                                    <li><Link onClick={ClickHandler} to="/network-locations">Network/Locations</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog">Blog</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget blog-widget">
                                <div className="widget-title">
                                    <h3>Recent Post</h3>
                                </div>
                                {blognew.map((blog, bl) => (
                                    <div className="post" key={bl}>
                                        <div className="detailsfooter">
                                            <h4><Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>{blog.title}</Link></h4>
                                            <span className="date">{blog.create_at}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ft-shape">
                    <img src={shape} alt="shape"/>
                </div>
                <div className="ft-shape-s1">
                    <svg width="254.000000pt" height="257.000000pt" viewBox="0 0 254.000000 257.000000"
                        preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,257.000000) scale(0.100000,-0.100000)" stroke="none">
                            <path d="M0 1341 c0 -1111 2 -1229 16 -1235 8 -3 56 -11 104 -16 206 -23 368 38 504 191 26 30 135 185 241 344 217 326 264 388 403 529 159 162 348 309 592 461 179 112 349 228 405 277 72 63 132 151 156 226 16 54 20 93 20 202 1 80 -5 157 -13 190 l-13 55 -1208 3 -1207 2 0 -1229z" />
                        </g>
                    </svg>
                </div>
                <div className="ft-shape-s2">
                    <svg width="230.000000pt" height="197.000000pt"
                        viewBox="0 0 230.000000 197.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,197.000000) scale(0.100000,-0.100000)" stroke="none">
                            <path d="M1885 1463 c-189 -11 -392 -50 -565 -109 -202 -68 -460 -209 -630 -345 -222 -176 -436 -441 -560 -694 -45 -91 -120 -283 -120 -305 0 -6 428 -10 1145 -10 l1145 0 0 719 c0 677 -1 720 -17 725 -25 8 -268 26 -312 24 -20 -1 -58 -3 -86 -5z" />
                        </g>
                    </svg>
                </div>
                <div className="ft-shape-s3">
                    <img src={shape2} alt=""/>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    {/* <div className="row align-items-center">
                        <div className="col col-lg-6 col-md-12 col-12">
                            <ul>
                                <li>Copyright &copy; 2022 //  All Right Reserved by <Link onClick={ClickHandler} to="/">Pegasus Global Design Partners</Link></li>
                            </ul>
                        </div>
                        <div className="col col-lg-6 col-md-12 col-12">
                            <div className="link">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about">Vision & Values</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog">History</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service">Awards</Link></li>
                                    <li><Link onClick={ClickHandler} to="/project-single">Leadership</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </footer>
    )
}

export default Footer2;
import { Subject } from '@material-ui/icons';
import React, { useState,useRef} from 'react'
import SimpleReactValidator from 'simple-react-validator';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ContactForm = () => {


    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_8w953ud', 'template_sqmedp8',form.current, 'z6qf6SgPIOYGUT_xP')
          .then((result) => {
              console.log(result.text);
              notify()
          }, (error) => {
              console.log(error.text);
          });

          e.target.reset()
          setForms({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        })
      };


      const notify = () => toast.success('Message succesfully sent!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

      


    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            sendEmail(e);
            setForms({
                name: '',
                email: '',
                subject: '',
                phone: '',
                message: ''
            })
        } else {
            validator.showMessages();
        }
    };

    return (
        <form ref={form} onSubmit={submitHandler} className="contact-validation-active" >
            <ToastContainer />
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            className='whitearea'
                            value={forms.subject}
                            type="text"
                            name="subject"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Subject" />
                        {validator.message('subject', forms.subject, 'required')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            className='whitearea'
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    {/* <div className="form-field">
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your phone" />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div> */}
                </div>
                <div className="col col-lg-6 col-12">
                    {/* <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.subject}
                            type="text"
                            name="subject">
                            <option>Choose a Service</option>
                            <option>Tax Management</option>
                            <option>Financial Advices</option>
                            <option>Risk Management</option>
                        </select>
                        {validator.message('subject', forms.subject, 'required')}
                    </div> */}
                </div>
                <div className="col col-lg-12 col-12 textWhite">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Message">
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
            </div>
            <div className="submit-area">
                <button
                //  onClick={() => window.location = 'mailto:info@pegasusgdp.com'+"?subject="+forms.name+"&body="+forms.message}
                 type="submit" className="theme-btn">Submit Now
                </button>

            </div>
        </form >
    )
}

export default ContactForm;
// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";

import blogSingleImg1 from "../images/blog/blog-single/img-1.jpg";
import blogSingleImg2 from "../images/blog/blog-single/img-2.jpg";
import blogSingleImg3 from "../images/blog/blog-single/img-3.jpg";



const blogs = [
    {
        id: '1',
        title: 'Southern California – Electrical & Mechanical Engineering',
        screens: blogImg1,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Marilou Kelleher',
        authorTitle:'Engineering & Design Network',
        create_at: '25 Dec, 2020',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
        NetworkArr:["Orlando, FL",
        "Los Angeles, CA",
        "Denver, CO",
        "Pittsburgh, PA"],
        NetworkArr2:["Bacolod, PH",
        "Shanghai, CN",
        "Shenzhen, CN",
        "Mexico City, MX"],
    },
    {
        id: '1',
        title: 'Southern California – Electrical & Mechanical Engineering',
        screens: blogImg1,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Marilou Kelleher',
        authorTitle:'Factory & Manufacturing Locations',
        create_at: '25 Dec, 2020',
        // blogSingleImg:blogSingleImg2, 
        comment:'35',
        blClass:'format-standard-image',
        NetworkArr:["Orlando, FL",
        "Chicago, IL",
        "Grand Rapids, MI",
        "Guadalajara, MX",
        "Shanghai, CN",],
        NetworkArr2:["Dongguan, CN",
        "Shenzhen, CN",
        "Xiamen, CN",
        "Taichung City, TW",
        "Phnom Penh, KH"],

    },

    
];
export default blogs;